import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Icon, Dropdown, Menu } from "antd";
import axios from "axios";
import JitsiLoader from './_Aux/JitsiLoader';
import SameWindow from "../GerirPlayer/SameWindowV2";

const JitsiMeeting = ({ match }) => {
    const jitsiContainerId = "jitsi-container-id";
    const [jitsi, setJitsi] = useState("");
    const [sessao, setSessao] = useState("");
    const [redirect, setRedirect] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(true);
    const [aSair, setASair] = useState(false);
    const [videos, setVideos] = useState("");
    const [anexos, setAnexos] = useState("");
    const [permissao, setPermissao] = useState("");
    const { getWindowArray, removeWindow } = SameWindow();

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        if (sessao != "") {
            const script = document.createElement("script");
            script.src = `https://${sessao.servidor}/external_api.js`;
            script.async = true;
            script.onload = () => resolveLoadJitsiScriptPromise(true);
            document.body.appendChild(script);
        }

        return loadJitsiScriptPromise;
    };

    const preload = () => {
        axios({
            method: "get",
            url: "/api/videoconferencia/get-meeting-details",
            params: {
                curso: localStorage.getItem("codigo_curso"),
                id: match.params.vconfid
            }
        })
            .then(response => {
                setSessao({
                    assunto: response.data.assunto,
                    meetingName: response.data.meetingName,
                    participantName: response.data.participant,
                    generatedPassword: response.data.generatedPassword,
                    moderatorID: response.data.moderatorID,
                    userID: response.data.userID,
                    gravarSessao: response.data.gravarSessao,
                    habilitarChat: response.data.habilitarChat,
                    gravarChat: response.data.gravarChat,
                    servidor: response.data.servidor
                })
                setVideos(response.data.sessaoVimeoID);
                setAnexos(response.data.ficheiro);
                setPermissao(response.data.permissaoDescarregar);
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO") {
                    setRedirectLink("/user");
                    setRedirect(true);
                }
                else if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    const validarSessaoAberta = () => {
        var totalTabs = getWindowArray()
        //if (totalTabs.length > 1) {
        //    removeWindow();
        //    setRedirectLink(`/curso/${match.params.curso}/vconf/${match.params.vconfid}/player-videoconferencia-blocked`);
        //    setRedirect(true);
        //}
        //else {
            axios({
                method: "get",
                url: "/api/videoconferencia/validar-sessao-aberta",
                params: {
                    meetingId: match.params.vconfid
                }
            })
                .then(response => {
                    //if (response.data)
                        preload();
                    //else {
                    //    removeWindow();
                    //    setRedirectLink(`/curso/${match.params.curso}/vconf/${match.params.vconfid}/player-videoconferencia-blocked`);
                    //    setRedirect(true);
                    //}
                })
                .catch(error => {
                    if (error.response.data === "SEM_PERMISSAO") {
                        setRedirectLink("/user");
                        setRedirect(true);
                    }
                    else if (error.response.status === 401) {
                        setRedirectLink("/login");
                        setRedirect(true);
                    }
                });
        //}
    }

    const montarMenuVideos = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <a href={`/curso/${match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/vconf/${video}/player-video-meets`} target="_blank">
                    Video {index + 1}
                </a>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const montarMenuAnexos = ficheiros => {
        var montar = JSON.parse(ficheiros).map((ficheiro) => (
            <Menu.Item>
                <Link to="#" onClick={() => downloadFicheiro(ficheiro.Nome, ficheiro.Caminho)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const downloadFicheiro = (nome, caminho) => {
        const link = document.createElement('a');
        link.href = caminho;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    const onPasswordRequired = () => {
        jitsi.executeCommand("password", sessao.generatedPassword);
    }

    const addParticipantEntry = (myUserID) => {
        axios({
            method: "post",
            url: "/api/videoconferencia/add-participant-entry",
            params: {
                meetingID: match.params.vconfid,
                TempId: myUserID,
                tipoEvento: 1
            }
        })
            .then(() => {

            })
            .catch(() => { });
    }

    const videoConferenceLeft = (e, jitsi) => {
        addParticipantExit(jitsi, 1)
    }

    const sair = () => {
        setASair(true);
        //jitsi.executeCommand('hangup');
    }

    const participantKickedOut = (e, jitsi) => {
        if (e.kicked.local) {
            //localStorage.removeItem("participanteNaMeet")

            addParticipantExit(jitsi, 2)

            setRedirectLink(`/curso/${match.params.curso}/curso-videoconferencias`);
            setRedirect(true);
        }
    }

    const addParticipantExit = (jitsi, tipoEvento) => {
        //var totalTabs = getWindowArray()

        //if (totalTabs.length <= 1)
            axios({
                method: "post",
                url: "/api/videoconferencia/add-participant-exit",
                params: {
                    meetingID: match.params.vconfid,
                    tipoEvento
                }
            })
                .then(response => {
                    jitsi.removeEventListener("participantKickedOut");
                    jitsi.removeEventListener("videoConferenceLeft");
                    jitsi.dispose();

                    var iframes = document.querySelectorAll('iframe');
                    for (var i = 0; i < iframes.length; i++) {
                        iframes[i].parentNode.removeChild(iframes[i]);
                    }
                    //removeWindow();
                    localStorage.removeItem("checkTab")

                    setRedirectLink(`/curso/${match.params.curso}/curso-videoconferencias`);
                    setRedirect(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        setRedirectLink("/login");
                        setRedirect(true);
                    }
                });
        //else {
        //    jitsi.removeEventListener("participantKickedOut");
        //    jitsi.removeEventListener("videoConferenceLeft");
        //    jitsi.dispose();

        //    var iframes = document.querySelectorAll('iframe');
        //    for (var i = 0; i < iframes.length; i++) {
        //        iframes[i].parentNode.removeChild(iframes[i]);
        //    }
        //    removeWindow();

        //    setRedirectLink(`/curso/${match.params.curso}/curso-videoconferencias`);
        //    setRedirect(true);
        //}
    }

    const selecionarResolucao = totalParticipantes => {
        var resolution = "";
        var constraints = {};

        if (totalParticipantes <= 4) {
            resolution = 720;
            constraints = {
                video: {
                    height: {
                        ideal: 720,
                        max: 1080,
                        min: 180
                    }
                }
            }
        }
        else if (totalParticipantes <= 25) {
            resolution = 480;
            constraints = {
                video: {
                    height: {
                        ideal: 360,
                        max: 480,
                        min: 180
                    }
                }
            }
        }
        else if (totalParticipantes <= 35) {
            resolution = 360;
            constraints = {
                video: {
                    height: {
                        ideal: 240,
                        max: 360,
                        min: 180
                    }
                }
            }
        }
        else {
            resolution = 240;
            constraints = {
                video: {
                    height: {
                        ideal: 240,
                        max: 240,
                        min: 180
                    }
                }
            }
        }

        return {
            resolution,
            constraints
        }
    }

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        var iframes = document.querySelectorAll('iframe');
        for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }

        const _jitsi = new window.JitsiMeetExternalAPI(sessao.servidor, {
            parentNode: document.getElementById(jitsiContainerId),
            roomName: sessao.meetingName,
            userInfo: {
                displayName: sessao.participantName
            },
            configOverwrite: {
                subject: sessao.assunto,
                prejoinPageEnabled: false,
                startWithAudioMuted: true,
                startWithVideoMuted: true,
                toolbarButtons: [
                    'microphone',
                    'camera',
                    'desktop',
                    'fullscreen',
                    'fodeviceselection',
                    /*'hangup',*/
                    'profile',
                    (sessao.habilitarChat ? 'chat' : ''),
                    'sharedvideo',
                    'settings',
                    'raisehand',
                    'videoquality',
                    'filmstrip',
                    'shortcuts',
                    'tileview',
                    'select-background',
                    'mute-everyone',
                    'shareaudio',
                    'participants-pane'
                ]
            },
            interfaceConfigOverwrite: {
                SETTINGS_SECTIONS: ['devices']
            }
        });

        var resolucaoInfo = selecionarResolucao(_jitsi._numberOfParticipants > sessao.totalParticipantes ? _jitsi._numberOfParticipants : sessao.totalParticipantes);

        _jitsi.configOverwrite = {
            ..._jitsi.configOverwrite,
            resolution: resolucaoInfo.resolution,
            constraints: resolucaoInfo.constraints
        }

        _jitsi.addEventListener('videoConferenceJoined', () => {
            if (_jitsi._numberOfParticipants === 1) {
                addParticipantExit(_jitsi, 1)
                openNotificationWithIcon("warning", "Atenção!", "Stepmeet vazia! Aguarde pelo Instrutor")
                setRedirectLink(`/curso/${match.params.curso}/vconf/${match.params.vconfid}/player-videoconferencia-aguardar`);
                setRedirect(true);
            }
            if (_jitsi._numberOfParticipants < 100) {
                setLoading(false);

                _jitsi.addEventListener("participantKickedOut", e => {
                    participantKickedOut(e, _jitsi);
                }, false);

                _jitsi.addEventListener("videoConferenceLeft", e => {
                    videoConferenceLeft(e, _jitsi);
                }, false);

                setJitsi(_jitsi);
            }
            else {
                _jitsi.removeEventListener('videoConferenceJoined');
                openNotificationWithIcon("warning", "Atenção!", "Stepmeet cheio!")
                setRedirectLink(`/curso/${match.params.curso}/curso-videoconferencias`);
                setRedirect(true);
            }
        });
    };

    useEffect(() => {
        validarSessaoAberta();
    }, []);

    useEffect(() => {
        if (sessao)
            initialiseJitsi();
    }, [sessao]);

    useEffect(() => {
        if (jitsi)
            jitsi.executeCommand('hangup');
    }, [aSair]);

    if (redirect)
        return <Redirect to={redirectLink} />

    return <>
        {loading ?
            <JitsiLoader />
            :
            null}
        <div className="jitsi-meeting">
            <div id={jitsiContainerId} style={{ height: '100vh', width: '100vw' }} />
            {!loading ?
                <div className="bloco-opcoes-player">
                    <Link to="#" className="opcao-player-desligar" title="Sair" onClick={sair}>
                        <i className="fas fa-phone icon-hang-up"></i>
                    </Link>
                    {permissao && videos ?
                        <Dropdown
                            overlay={montarMenuVideos(videos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-videos" title="Vídeos">
                                <Icon type="video-camera" />
                            </Link>
                        </Dropdown> : null}
                    {anexos ?
                        <Dropdown
                            overlay={montarMenuAnexos(anexos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-anexos" title="Anexos">
                                <Icon type="paper-clip" />
                            </Link>
                        </Dropdown> : null}
                </div>
                : null}
        </div>
    </>;
};

export default JitsiMeeting;