import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Icon, Dropdown, Menu } from "antd";
import axios from "axios";
import JitsiLoader from './_Aux/JitsiLoader';

const JitsiMeeting = ({ match }) => {
    const jitsiContainerId = "jitsi-container-id";
    const [jitsi, setJitsi] = useState("");
    const [sessao, setSessao] = useState("");
    const [redirect, setRedirect] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [loading, setLoading] = useState(true);
    const [aSair, setASair] = useState(false);
    const [videos, setVideos] = useState("");
    const [anexos, setAnexos] = useState("");

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        if (sessao != "") {
            const script = document.createElement("script");
            script.src = `https://${sessao.servidor}/external_api.js`;
            script.async = true;
            script.onload = () => resolveLoadJitsiScriptPromise(true);
            document.body.appendChild(script);
        }

        return loadJitsiScriptPromise;
    };

    const preload = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-videoconferencia/get-meeting-details",
            params: {
                id: match.params.vconfid,
                userID: localStorage.getItem("videoconf_userid")
            }
        })
            .then(response => {
                setSessao({
                    assunto: response.data.assunto,
                    meetingName: response.data.meetingName,
                    participantName: response.data.participant,
                    generatedPassword: response.data.generatedPassword,
                    moderatorID: response.data.moderatorID,
                    userID: response.data.userID,
                    moderadorVideoSom: response.data.moderadorVideoSom,
                    gravarSessao: response.data.gravarSessao,
                    habilitarChat: response.data.habilitarChat,
                    audioVideoAtivo: response.data.audioVideoAtivo,
                    gravarChat: response.data.gravarChat,
                    streaming: response.data.streaming,
                    streamingKey: response.data.streamingKey,
                    servidor: response.data.servidor,
                    totalParticipantes: response.data.totalParticipantes
                })
                setVideos(response.data.sessaoVimeoID);
                setAnexos(response.data.ficheiro);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    const montarMenuVideos = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <a href={`/${match.params.entidade}/videoconferencia/${video}/player-video-meets`} target="_blank">
                    Video {index + 1}
                </a>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const montarMenuAnexos = ficheiros => {
        var montar = JSON.parse(ficheiros).map((ficheiro) => (
            <Menu.Item>
                <Link to="#" onClick={() => downloadFicheiro(ficheiro.Nome, ficheiro.Caminho)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const downloadFicheiro = (nome, caminho) => {
        const link = document.createElement('a');
        link.href = caminho;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    const videoConferenceLeft = (e, jitsi) => {
        addParticipantExit(jitsi, 1)
    }

    const sair = () => {
        setASair(true);
    }

    const participantKickedOut = (e, jitsi) => {
        if (e.kicked.local) {
            addParticipantExit(jitsi, 2)
        }
    }

    const addParticipantExit = (jitsi, tipoEvento) => {
        axios({
            method: "post",
            url: "/api/pagina-entidade-videoconferencia/add-participant-exit",
            params: {
                userID: localStorage.getItem("videoconf_userid"),
                entidadeId: localStorage.getItem("entidadeId"),
                meetingID: match.params.vconfid,
                tipoEvento
            }
        })
            .then(response => {
                jitsi.removeEventListener("participantKickedOut");
                jitsi.removeEventListener("videoConferenceLeft");
                jitsi.dispose();

                setRedirectLink(`/${match.params.entidade}`);
                setRedirect(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    const selecionarResolucao = totalParticipantes => {
        var resolution = "";
        var constraints = {};

        if (totalParticipantes <= 4) {
            resolution = 720;
            constraints = {
                video: {
                    height: {
                        ideal: 720,
                        max: 1080,
                        min: 180
                    }
                }
            }
        }
        else if (totalParticipantes <= 25) {
            resolution = 480;
            constraints = {
                video: {
                    height: {
                        ideal: 360,
                        max: 480,
                        min: 180
                    }
                }
            }
        }
        else if (totalParticipantes <= 35) {
            resolution = 360;
            constraints = {
                video: {
                    height: {
                        ideal: 240,
                        max: 360,
                        min: 180
                    }
                }
            }
        }
        else {
            resolution = 240;
            constraints = {
                video: {
                    height: {
                        ideal: 240,
                        max: 240,
                        min: 180
                    }
                }
            }
        }

        return {
            resolution,
            constraints
        }
    }

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        var iframes = document.querySelectorAll('iframe');
        for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }

        const _jitsi = new window.JitsiMeetExternalAPI(sessao.servidor, {
            parentNode: document.getElementById(jitsiContainerId),
            roomName: sessao.meetingName,
            userInfo: {
                displayName: sessao.participantName
            },
            configOverwrite: {
                subject: sessao.assunto,
                prejoinPageEnabled: false,
                startWithAudioMuted: !sessao.audioVideoAtivo,
                startWithVideoMuted: !sessao.audioVideoAtivo,
                toolbarButtons: [
                    (!sessao.moderadorVideoSom ? "microphone" : ""),
                    (!sessao.moderadorVideoSom ? "camera" : ""),
                    'desktop',
                    'fullscreen',
                    'fodeviceselection',
                    /*'hangup',*/
                    'profile',
                    (sessao.habilitarChat ? 'chat' : ''),
                    'sharedvideo',
                    'settings',
                    'raisehand',
                    'videoquality',
                    'filmstrip',
                    'shortcuts',
                    'tileview',
                    'select-background',
                    'mute-everyone',
                    'shareaudio',
                    'participants-pane'
                ]
            },
            interfaceConfigOverwrite: {
                SETTINGS_SECTIONS: ['devices']
            }
        });

        var resolucaoInfo = selecionarResolucao(_jitsi._numberOfParticipants > sessao.totalParticipantes ? _jitsi._numberOfParticipants : sessao.totalParticipantes);

        _jitsi.configOverwrite = {
            ..._jitsi.configOverwrite,
            resolution: resolucaoInfo.resolution,
            constraints: resolucaoInfo.constraints
        }

        _jitsi.addEventListener('videoConferenceJoined', () => {
            if (_jitsi._numberOfParticipants === 1) {
                setRedirectLink(`/${match.params.entidade}/videoconferencia-aguardar/${match.params.vconfid}`);
                setRedirect(true);
            }

            if (_jitsi._numberOfParticipants < 100) {
                setLoading(false);

                _jitsi.addEventListener("participantKickedOut", e => {
                    participantKickedOut(e, _jitsi);
                }, false);

                _jitsi.addEventListener("videoConferenceLeft", e => {
                    videoConferenceLeft(e, _jitsi);
                }, false);

                setJitsi(_jitsi);
            }
            else {
                _jitsi.removeEventListener('videoConferenceJoined');
                openNotificationWithIcon("warning", "Atenção!", "Stepmeet cheio!")
                setRedirectLink(`/${match.params.entidade}/videoconferencia-aguardar-cheio/${match.params.vconfid}`);
                setRedirect(true);
            }
        });
    };

    useEffect(() => {
        preload();
    }, []);

    useEffect(() => {
        if (sessao)
            initialiseJitsi();
    }, [sessao]);

    useEffect(() => {
        if (jitsi)
            jitsi.executeCommand('hangup');
    }, [aSair]);

    if (redirect)
        return <Redirect to={redirectLink} />

    return <>
        {loading ?
            <JitsiLoader />
            :
            null}
        <div className="jitsi-meeting">
            <div id={jitsiContainerId} style={{ height: '100vh', width: '100vw' }} />
            {!loading ?
                <div className="bloco-opcoes-player">
                    <Link to="#" className="opcao-player-desligar" title="Sair" onClick={sair}>
                        <i className="fas fa-phone icon-hang-up"></i>
                    </Link>
                    {videos ?
                        <Dropdown
                            overlay={montarMenuVideos(videos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-videos" title="Vídeos">
                                <Icon type="video-camera" />
                            </Link>
                        </Dropdown> : null}
                    {anexos ?
                        <Dropdown
                            overlay={montarMenuAnexos(anexos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-anexos" title="Anexos">
                                <Icon type="paper-clip" />
                            </Link>
                        </Dropdown> : null}
                </div>
                : null}
        </div>
    </>;
};

export default JitsiMeeting;