import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Modal, Button, Icon, Dropdown, Menu } from "antd";
import axios from "axios";
import JitsiLoader from './_Aux/JitsiLoader';
import SameWindow from "./SameWindowV2";

const JitsiMeetingGeral = ({ match }) => {
    const jitsiContainerId = "jitsi-container-id";
    const [jitsi, setJitsi] = useState("");
    const [sessao, setSessao] = useState("");
    const [redirect, setRedirect] = useState("");
    const [redirectLink, setRedirectLink] = useState("");
    const [moderador, setModerador] = useState(false);
    const [gravadorAtivo, setGravadorAtivo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visibleEncerrar, setVisibleEncerrar] = useState(false);
    const [aSair, setASair] = useState(false);
    const [tempDisabled, setTempDisabled] = useState(false);
    const [videos, setVideos] = useState("");
    const [anexos, setAnexos] = useState("");
    const { getWindowArray, removeWindow } = SameWindow();

    const confirm = Modal.confirm;

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        if (sessao != "") {
            const script = document.createElement("script");
            script.src = `https://${sessao.servidor}/external_api.js`;
            script.async = true;
            script.onload = () => resolveLoadJitsiScriptPromise(true);
            document.body.appendChild(script);
        }

        return loadJitsiScriptPromise;
    };

    const openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message: message,
            description: description
        });
    };

    const preload = () => {
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/get-meeting-details",
            params: {
                curso: localStorage.getItem("codigo_curso"),
                id: match.params.vconfid
            }
        })
            .then(response => {
                setSessao({
                    assunto: response.data.assunto,
                    meetingName: response.data.meetingName,
                    participantName: response.data.participant,
                    generatedPassword: response.data.generatedPassword,
                    moderatorID: response.data.moderatorID,
                    userID: response.data.userID,
                    gravarSessao: response.data.gravarSessao,
                    habilitarChat: response.data.habilitarChat,
                    audioVideoAtivo: response.data.audioVideoAtivo,
                    gravarChat: response.data.gravarChat,
                    streaming: response.data.streaming,
                    streamingKey: response.data.streamingKey,
                    servidor: response.data.servidor,
                    totalParticipantes: response.data.totalParticipantes
                })
                setVideos(response.data.sessaoVimeoID);
                setAnexos(response.data.ficheiro);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setRedirectLink("/login");
                    setRedirect(true);
                }
            });
    }

    const montarMenuVideos = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <a href={`/gestao-videoconferencia/${video}/player-video-meets`} target="_blank">
                    Video {index + 1}
                </a>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const montarMenuAnexos = ficheiros => {
        var montar = JSON.parse(ficheiros).map((ficheiro) => (
            <Menu.Item>
                <Link to="#" onClick={() => downloadFicheiro(ficheiro.Nome, ficheiro.Caminho)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    const downloadFicheiro = (nome, caminho) => {
        const link = document.createElement('a');
        link.href = caminho;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    const videoConferenceLeft = (e, jitsi) => {
        addParticipantExit(jitsi, 1)
    }

    const sair = () => {
        var totalTabs = getWindowArray()
        if (moderador && totalTabs.length <= 1) {
            setVisibleEncerrar(true);
        }
        else {
            setASair(true);
        }
    }

    const sairStepmeet = () => {
        setVisibleEncerrar(false);
        setASair(true);
    }

    const sairTodosStepmeet = () => {
        confirm({
            title: "Pretende marcar como realizada esta Stepmeet?",
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
                setVisibleEncerrar(false);
                setASair(true);

                const lista = jitsi.getParticipantsInfo();
                const _lista = lista.filter(x => x.participantId !== jitsi._myUserID)

                //DESLIGA O GRAVADOR CASO LIGADO
                if (gravadorAtivo)
                    jitsi.executeCommand('stopRecording',
                        'file'
                    );

                _lista.map(item => {
                    jitsi.executeCommand('kickParticipant',
                        item.participantId
                    );
                })

                marcarRealizadaSair();
            },
            onCancel: () => {
                setVisibleEncerrar(false);
                setASair(true);

                const lista = jitsi.getParticipantsInfo();
                const _lista = lista.filter(x => x.participantId !== jitsi._myUserID)

                //DESLIGA O GRAVADOR CASO LIGADO
                if (gravadorAtivo)
                    jitsi.executeCommand('stopRecording',
                        'file'
                    );

                _lista.map(item => {
                    jitsi.executeCommand('kickParticipant',
                        item.participantId
                    );
                })
            }
        });
    }

    const marcarRealizadaSair = () => {
        axios({
            method: "put",
            url: "/api/gestao-videoconferencia/marcar-realizada-sair",
            params: {
                videoConfId: match.params.vconfid
            }
        })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível marcar a Stepmeet como realizada!")
            });
    }

    const participantKickedOut = (e, jitsi) => {
        if (e.kicked.local) {
            addParticipantExit(jitsi, 2)
        }
    }

    const addParticipantExit = (jitsi, tipoEvento) => {
        var totalTabs = getWindowArray()
        if (totalTabs.length <= 1)
            axios({
                method: "post",
                url: "/api/gestao-videoconferencia/add-participant-exit",
                params: {
                    meetingID: match.params.vconfid,
                    tipoEvento
                }
            })
                .then(response => {
                    jitsi.removeEventListener("participantKickedOut");
                    jitsi.removeEventListener("participantRoleChanged");
                    if (moderador)
                        jitsi.removeEventListener("recordingStatusChanged");
                    jitsi.removeEventListener("videoConferenceLeft");
                    jitsi.dispose();
                    removeWindow();

                    setRedirectLink(localStorage.getItem("player_back_url"));
                    setRedirect(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        setRedirectLink("/login");
                        setRedirect(true);
                    }
                });
        else {
            jitsi.removeEventListener("participantKickedOut");
            jitsi.removeEventListener("participantRoleChanged");
            if (moderador)
                jitsi.removeEventListener("recordingStatusChanged");
            jitsi.removeEventListener("videoConferenceLeft");
            jitsi.dispose();
            removeWindow();

            setRedirectLink(localStorage.getItem("player_back_url"));
            setRedirect(true);
        }
    }

    const participantRoleChanged = (e) => {
        if (e.role === "moderator") {
            setModerador(true);
        }
    }

    const recordingStatusChanged = (e) => {
        setGravadorAtivo(e.on);
        disabledTemporario();
    }

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        var iframes = document.querySelectorAll('iframe');
        for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }

        const _jitsi = new window.JitsiMeetExternalAPI(sessao.servidor, {
            parentNode: document.getElementById(jitsiContainerId),
            roomName: sessao.meetingName,
            userInfo: {
                displayName: sessao.participantName
            },
            configOverwrite: {
                subject: sessao.assunto,
                prejoinPageEnabled: false,
                startWithAudioMuted: !sessao.audioVideoAtivo,
                startWithVideoMuted: !sessao.audioVideoAtivo,
                toolbarButtons: [
                    'microphone',
                    'camera',
                    'desktop',
                    'fullscreen',
                    'fodeviceselection',
                    'profile',
                    (sessao.habilitarChat ? 'chat' : ''),
                    'sharedvideo',
                    'settings',
                    'raisehand',
                    'videoquality',
                    'filmstrip',
                    'shortcuts',
                    'tileview',
                    'select-background',
                    'mute-everyone',
                    'mute-video-everyone',
                    'shareaudio',
                    'participants-pane'
                ]
            },
            interfaceConfigOverwrite: {
                SETTINGS_SECTIONS: ['devices', 'moderator']
            }
        });

        _jitsi.addEventListener('videoConferenceJoined', () => {
            if (_jitsi._numberOfParticipants < 100) {
                setLoading(false);
                if (sessao.gravarSessao) {
                    _jitsi.executeCommand('startRecording', {
                        mode: 'file',
                        dropboxToken: 'X0uFsdtIOJAAAAAAAAAAAbts5HmYd6D9eDQJtXPsl7kdBb9B8RA2YPWZXI_ks4Lo', //dropbox oauth2 token.
                    });
                    _jitsi.addEventListener("recordingStatusChanged", recordingStatusChanged);
                }

                _jitsi.addEventListener("participantKickedOut", e => {
                    participantKickedOut(e, _jitsi);
                }, false);

                _jitsi.addEventListener("participantRoleChanged", participantRoleChanged);

                _jitsi.addEventListener("videoConferenceLeft", e => {
                    videoConferenceLeft(e, _jitsi);
                }, false);

                setJitsi(_jitsi);
            }
            else {
                _jitsi.removeEventListener('videoConferenceJoined');
                openNotificationWithIcon("warning", "Atenção!", "Stepmeet não suporta mais participantes!")
                setRedirectLink(localStorage.getItem("player_back_url"));
                setRedirect(true);
            }
        });
    };

    const IniciarGravacao = () => {
        confirm({
            title: "Pretende iniciar a gravação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                jitsi.executeCommand('startRecording', {
                    mode: 'file',
                    dropboxToken: 'X0uFsdtIOJAAAAAAAAAAAbts5HmYd6D9eDQJtXPsl7kdBb9B8RA2YPWZXI_ks4Lo', //dropbox oauth2 token.
                });
            }
        });
    }

    const pararGravacao = () => {
        confirm({
            title: "Pretende parar a gravação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                jitsi.executeCommand('stopRecording',
                    'file'
                );
            }
        });
    }

    const disabledTemporario = () => {
        setTempDisabled(true)
        setTimeout(() => {
            setTempDisabled(false)
        }, 3000)
    }

    useEffect(() => {
        preload();
    }, []);

    useEffect(() => {
        if (sessao)
            initialiseJitsi();
    }, [sessao]);

    useEffect(() => {
        if (jitsi)
            jitsi.executeCommand('hangup');
    }, [aSair]);

    if (redirect)
        return <Redirect to={redirectLink} />

    return <>
        {loading ?
            <JitsiLoader />
            :
            null}
        <div className="jitsi-meeting">
            <div id={jitsiContainerId} style={{ height: '100vh', width: '100vw' }} />
            {!loading ?
                <div className="bloco-opcoes-player">
                    <Link to="#" className="opcao-player-desligar" title="Sair" onClick={sair}>
                        <i className="fas fa-phone icon-hang-up"></i>
                    </Link>
                    {videos ?
                        <Dropdown
                            overlay={montarMenuVideos(videos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-videos" title="Vídeos">
                                <Icon type="video-camera" />
                            </Link>
                        </Dropdown> : null}
                    {anexos ?
                        <Dropdown
                            overlay={montarMenuAnexos(anexos)}
                            placement="bottomLeft"
                        >
                            <Link to="#" className="opcao-player-anexos" title="Anexos">
                                <Icon type="paper-clip" />
                            </Link>
                        </Dropdown> : null}
                    {moderador && jitsi && sessao.gravarSessao ?
                        !gravadorAtivo ?
                            <Link to="#" className="opcao-gravador-desligado" title="Iniciar Gravação" disabled={tempDisabled} onClick={IniciarGravacao}>REC</Link>
                            :
                            <Link to="#" className="opcao-gravador-ligado" title="Parar Gravação" disabled={tempDisabled} onClick={pararGravacao}>REC</Link>
                        : null}
                </div>
                : null}
        </div>
        <Modal
            visible={visibleEncerrar}
            width="450px"
            className="modal-encerrar-stepmeet"
            footer={[
                <Button key="back" className="btn-cancelar" onClick={() => setVisibleEncerrar(false)}>
                    Cancelar
                            </Button>,
                <Button key="submit" className="btn-para-todos" onClick={sairTodosStepmeet}>
                    Para todos
                            </Button>,
                <Button key="submit" className="btn-para-mim" onClick={sairStepmeet}>
                    Para mim
                            </Button>,
            ]}
        >
            <Icon type="question-circle" className="icon-modal-encerrer-stepmeet" />
            <p className="texto-modal-encerrar-stepmeet">Encerrar Stepmeet</p>
        </Modal>
        <Modal
            visible={aSair}
            maskClosable={false}
            className="modal-loading"
            footer={null}
            closable={false}
        >
            <div className="modal-loading-bloco">
                <p>
                    <Icon type="loading" />
                </p>
                <p className="texto">A sair...</p>
            </div>
        </Modal>
    </>;
};

export default JitsiMeetingGeral;